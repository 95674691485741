import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;

  label {
    font-weight: bold;
    font-size: 0.9rem;
  }
`;

const Content = styled.div<{ isFocused: boolean }>`
  position: relative;
  border: 1px solid ${(props) => (props.isFocused ? '#007BFF' : '#ccc')};
  border-radius: 4px;
  padding: 8px;
  background: white;
  max-height: 150px;
  input {
    width: 100%;
    border: none;
    outline: none;
  }
`;

const Dropdown = styled.ul`
  position: absolute;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-top: none;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 10;
  max-height: 150px;
  overflow-y: auto;
`;

const DropdownItem = styled.li`
  padding: 8px;
  cursor: pointer;
  &:hover {
    background: #007BFF;
    color: white;
  }
`;

interface Option {
  label: string;
  value: number | string;
}

interface AutoCompleteSelectProps {
  label: string;
  name: string;
  options: Option[];
  onChange?: (value: number | string) => void;
}

export const AutoCompleteSelect: React.FC<AutoCompleteSelectProps> = ({
  label,
  name,
  options,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const filteredOptions = options.filter((option) => option.label
    .toLowerCase().includes(inputValue.toLowerCase()));

  useEffect(() => {
    if (inputValue === '') {
      onChange && onChange(0);
    }
  }, [inputValue]);

  return (
    <Container>
      <label htmlFor={name}>{label}</label>
      <Content isFocused={isFocused}>
        <input
          type="text"
          name={name}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setTimeout(() => setIsFocused(false), 200)}
          placeholder="Selecione"
        />
        {isFocused && filteredOptions.length > 0 && (
          <Dropdown>
            {filteredOptions.map((option) => (
              <DropdownItem
                key={option.value}
                onMouseDown={() => {
                  setInputValue(option.label);
                  if (onChange) onChange(option.value);
                }}
              >
                {option.label}
              </DropdownItem>
            ))}
          </Dropdown>
        )}
      </Content>
    </Container>
  );
};
