import { Col } from 'react-bootstrap';
import { CloseButton, ImageContainer, StyledImage } from './styles';

interface ImageInputWithPreviewProps {
  preview?: string | null;
  handleDeleteUploadedImage: () => void;
  handleUploadImage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ImageInputWithPreview = ({
  preview,
  handleDeleteUploadedImage,
  handleUploadImage,
}: ImageInputWithPreviewProps) => (
  <>
    <Col>
      {preview && (
      <ImageContainer>
        <CloseButton
          type="button"
          onClick={handleDeleteUploadedImage}
        >
          x
        </CloseButton>
        <StyledImage
          src={preview}
          alt="Preview"
        />
      </ImageContainer>
      )}
    </Col>
    <input type="file" accept="image/*" onChange={handleUploadImage} />
  </>
);

export default ImageInputWithPreview;
