/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { SiCircle } from 'react-icons/si';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IoScanCircleOutline } from 'react-icons/io5';
import { BsCircle } from 'react-icons/bs';
import Swal from 'sweetalert2';
import moment from 'moment';
import { Button } from '../../../components/Button';
import theme from '../../../styles/theme';
import { Header } from './styles';
import api from '../../../services/api';
import { ICurso } from '../../../interfaces/ICurso';
import { IFase } from '../../../interfaces/IFase';
import { BoxItemGame } from '../../../components/BoxItemGame';
import { ITarefa } from '../../../interfaces/ITarefa';
import { ModalFormFase } from '../components/ModalFormFase';
import { ModalFormTarefa } from '../components/ModalFormTarefa';
import { ModalFormCurso } from '../components/ModalFormCurso';
import { ModalFormAgendamento } from '../components/ModalFormAgendamento';
import { ModalCourseImport } from '../components/ModalImport';

export const KanbanCursos = () => {
  const { categoryId, courseId } = useParams<{ categoryId: string; courseId: string }>();
  const history = useHistory();
  const [curso, setCurso] = useState<ICurso>();
  const [fases, setFases] = useState<IFase[]>([]);
  const [showModalCurso, setShowModalCurso] = useState(false);
  const [showModalFormFase, setShowModalFormFase] = useState(false);
  const [showModalFormTarefa, setShowModalFormTarefa] = useState(false);
  const [faseSelecionada, setFaseSelecionada] = useState<IFase>();
  const [tarefaSelecionada, setTarefaSelecionada] = useState<ITarefa>();
  const [tarefas, setTarefas] = useState<ITarefa[]>([]);
  const [isOpenModalFormAgendamento, setIsOpenModalFormAgendamento] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);

  const handleDuplicarTarefa = useCallback(
    async (indexItem: number) => {
      try {
        const { data } = await api.post(`${faseSelecionada?.id}/tarefa`, {
          ...tarefas[indexItem],
          id: undefined,
          nome: `Cópia de: ${tarefas[indexItem].nome}`,
          quizzes: [],
          arquivo: {
            ...tarefas[indexItem].arquivo,
            id: undefined,
          },
          video: {
            ...tarefas[indexItem].video,
            id: undefined,
          },
        });
        setTarefas((old) => {
          old.splice(indexItem + 1, 0, data.data);
          return [...old];
        });
      } catch {
        toast.error('Erro ao duplicar tarefa');
      }
    },
    [faseSelecionada, tarefas],
  );

  const handleDuplicarFase = useCallback(
    async (indexItem: number) => {
      try {
        const { data } = await api.post(`${curso?.id}/fase`, {
          ...fases[indexItem],
          id: undefined,
          titulo: `Cópia de: ${fases[indexItem].titulo}`,
          ordem: undefined,
          video: {
            ...fases[indexItem].video,
            id: undefined,
          },
          arquivo: fases[indexItem].arquivo
            ? {
              ...fases[indexItem].arquivo,
              id: undefined,
            }
            : null,
        });
        setFases((old) => {
          old.splice(indexItem + 1, 0, data.data);
          return [...old];
        });
        toast.success('Fase duplicada com sucesso');
      } catch {
        toast.error('Erro ao duplicar fase');
      }
    },
    [curso, fases],
  );

  const getCurso = useCallback(async () => {
    try {
      const { data } = await api.get(`curso/${courseId}`);
      setCurso(data.data);
    } catch (err: any) {
      if (err.response.data.message) {
        toast.error(err.response.data.message);
        return;
      }
      toast.error('Erro ao buscar dados do curso selecionado');
    }
  }, [courseId]);

  useEffect(() => {
    const getFases = async () => {
      try {
        const { data } = await api.get(`${courseId}/fase`);
        setFases(data.data);
      } catch (err: any) {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
          return;
        }
        toast.error('Erro ao buscar fases');
      }
    };
    if (courseId) {
      getCurso();
      getFases();
    }

    return () => {
      setCurso(undefined);
      setFases([]);
    };
  }, [courseId, getCurso, showImportModal, setShowImportModal]);

  const handleCloseModalFormFase = () => {
    setShowModalFormFase(false);
    setFaseSelecionada(undefined);
    setTarefas([]);
  };

  const handleSelectItemFase = useCallback(
    async (idFase: number) => {
      try {
        const findFase = fases.find((item) => item.id === idFase);
        setFaseSelecionada(findFase);
        const { data } = await api.get(`${idFase}/tarefa`);
        setTarefas(data.data);
        setTarefaSelecionada(undefined);
      } catch (err: any) {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
          return;
        }
        toast.error('Erro ao buscar dados da fase selecionada');
      }
    },
    [fases],
  );

  const handleSetFasedeCurso = (data: IFase, update?: boolean) => {
    if (update) {
      setFases((old) => [...old].map((object) => {
        if (object.id === data.id) {
          return data;
        }
        return object;
      }));
    } else {
      setFases((old) => [...old, data]);
    }
    getCurso();
  };

  const handleSelectItemTarefa = useCallback(
    (idTarefa: number) => {
      const findTarefa = tarefas.find((item) => item.id === idTarefa);
      setTarefaSelecionada(findTarefa);
    },
    [tarefas],
  );

  const handleSetTarefa = (data: ITarefa, update?: boolean) => {
    if (update) {
      setTarefas((old) => [...old].map((object) => {
        if (object.id === data.id) {
          return data;
        }
        return object;
      }));
    } else {
      setTarefas((old) => [...old, data]);
    }
    getCurso();
  };

  const handleCloseModalFormTarefa = () => {
    setShowModalFormTarefa(false);
    setTarefaSelecionada(undefined);
  };

  const handleDisableScheduler = () => {
    api
      .post(`/curso/${curso?.id}/cancelar-cadastro`)
      .then(() => {
        getCurso();
        toast.success('Agendamento cancelado!');
      })
      .catch((error) => {
        if (error.response.data) {
          toast.error(error.response.data.errors.join(','));
        } else {
          toast.error('Erro ao cancelar agendamento!');
        }
      });
  };

  const handleDeleteFase = useCallback(
    (idFase: number) => {
      Swal.fire({
        title: 'Deseja realmente excluir?',
        text: 'Você não será capaz de reverter isso.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#ef5350',
        cancelButtonColor: '#757575',
        confirmButtonText: 'Sim, excluir',
        cancelButtonText: 'Cancelar',
        denyButtonColor: '#757575',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await api.delete(`${curso?.id}/fase/${idFase}`);
            setFases((old) => [...old.filter((item) => item.id !== idFase)]);
            toast.success('A fase foi removida');
          } catch (err: any) {
            if (err.response.data.message) {
              toast.error(err.response.data.message);
              return;
            }
            toast.error('Erro ao excluir fase');
          }
        }
      });
    },
    [curso],
  );

  const handleDeleteTarefa = useCallback(
    (idTarefa: number) => {
      Swal.fire({
        title: 'Deseja realmente excluir?',
        text: 'Você não será capaz de reverter isso.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#ef5350',
        cancelButtonColor: '#757575',
        confirmButtonText: 'Sim, excluir',
        cancelButtonText: 'Cancelar',
        denyButtonColor: '#757575',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await api.delete(`${faseSelecionada?.id}/tarefa/${idTarefa}`);
            setTarefas((old) => [...old.filter((item) => item.id !== idTarefa)]);
            toast.success('A tarefa foi removida');
          } catch (err: any) {
            if (err.response.data.message) {
              toast.error(err.response.data.message);
              return;
            }
            toast.error('Erro ao excluir tarefa');
          }
        }
      });
    },
    [faseSelecionada],
  );

  const handleDeleteCurso = useCallback(
    (cursoId: number) => {
      Swal.fire({
        title: 'Deseja realmente excluir?',
        text: 'Você não será capaz de reverter isso.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#ef5350',
        cancelButtonColor: '#757575',
        confirmButtonText: 'Sim, excluir',
        cancelButtonText: 'Cancelar',
        denyButtonColor: '#757575',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await api.delete(`curso/${cursoId}`);
            toast.success('O curso foi removido');
            history.push('/categories');
          } catch (err: any) {
            if (err.response.data.message) {
              toast.error(err.response.data.message);
              return;
            }
            toast.error('Erro ao excluir curso');
          }
        }
      });
    },
    [history],
  );

  const handleOnDragEndFase = useCallback(
    async (resultIndex: number, resultDestination: number) => {
      setFases((old) => {
        const [reorderedItem] = old.splice(resultIndex, 1);
        old.splice(resultDestination, 0, reorderedItem);
        return old;
      });
      const ordernacaoFases = fases
        .map((p, index) => ({
          id: p.id,
          ordem: index,
        }))
        .concat([]);
      try {
        await api.put(`${curso?.id}/fase/ordenar`, ordernacaoFases);
      } catch {
        toast.error('Erro ao ordernar fases');
      }
    },
    [fases, curso],
  );

  const handleFinalizarCurso = useCallback(async () => {
    try {
      await api.post(`curso/${curso?.id}/finalizar-cadastro`);
      const { data } = await api.get(`curso/${courseId}`);
      setCurso(data.data);
      Swal.fire({
        title: 'Parabéns! Jogo criado e já disponível para os jogadores.',
        icon: 'success',
        confirmButtonColor: theme.colors.main,
      });
    } catch (err: any) {
      if (err.response.data.errors) {
        toast.error(err.response.data.errors[0]);
        return;
      }
      toast.error('Erro ao finalizar game');
    }
  }, [curso, history, courseId]);

  const handleOnDragEndTarefa = useCallback(
    async (resultIndex: number, resultDestination: number) => {
      setTarefas((old) => {
        const [reorderedItem] = old.splice(resultIndex, 1);
        old.splice(resultDestination, 0, reorderedItem);
        return old;
      });
      const ordernacaoTarefas = tarefas
        .map((p, index) => ({
          id: p.id,
          ordem: index,
        }))
        .concat([]);
      try {
        await api.put(`${faseSelecionada?.id}/tarefa/ordenar`, ordernacaoTarefas);
      } catch {
        toast.error('Erro ao ordernar tarefa');
      }
    },
    [tarefas, faseSelecionada],
  );

  const handleUpdateCurso = () => {
    getCurso();
  };

  const mapCursoItens = (cursos: ICurso[]): ICurso[] => cursos.map((_curso) => ({
    ..._curso,
    extraInfo:
        _curso.dataLancamento !== null
          ? (curso?.lancado ? 'Iniciado em ' : 'Agendado para ')
            + moment(_curso.dataLancamento).local().format('DD/MM/YYYY HH:mm')
          : null,
  }));

  const handleCloseImportModal = () => {
    setShowImportModal(false);
  };

  const handleDisplayTitle = (item: any) => <span>{item?.nome ?? item.titulo}</span>;

  return (
    <>
      {curso && (
        <Prompt
          when={!curso?.cadastroFinalizado}
          message="Você não concluiu o jogo. Tem certeza que deseja sair?"
        />
      )}
      {curso && (
        <>
          <ModalFormFase
            onSetFase={handleSetFasedeCurso}
            cursoId={curso?.id}
            fase={faseSelecionada}
            onCloseModal={handleCloseModalFormFase}
            showModalFormCurso={showModalFormFase}
          />
          <ModalFormAgendamento
            idCurso={curso?.id}
            onClose={() => {
              getCurso();
              setIsOpenModalFormAgendamento(false);
            }}
            isOpen={isOpenModalFormAgendamento}
          />
        </>
      )}
      {faseSelecionada && (
        <ModalFormTarefa
          faseId={faseSelecionada.id}
          onSetTarefa={handleSetTarefa}
          tarefa={tarefaSelecionada}
          onCloseModal={handleCloseModalFormTarefa}
          showModal={showModalFormTarefa}
        />
      )}

      <ModalFormCurso
        onSetCurso={handleUpdateCurso}
        idCurso={curso?.id}
        categoryId={parseInt(categoryId, 10)}
        onCloseModal={() => setShowModalCurso(false)}
        showModalFormCurso={showModalCurso}
      />

      <ModalCourseImport onCloseModal={handleCloseImportModal} showModal={showImportModal} />

      <Header className="mt-3">
        <Row>
          <Col md={11} className="col-spacing d-flex">
            <SiCircle size={35} />
            <div style={{ flex: 1 }}>
              <h5>Trilha de aprendizado</h5>
              <div style={{ display: 'flex', width: '110%' }}>
                <p>
                  Vamos colocar a mão na massa! Você deve cadastrar um curso por vez e em cada curso
                  deve existir ao menos uma fase e uma tarefa. Vamos lá?
                </p>
                {courseId && (
                <>
                  {!curso?.dataLancamento && (
                    <Button
                      type="button"
                      background={theme.colors.main}
                      color="#fff"
                      onClick={() => setShowImportModal(true)}
                      className="ml-2"
                      style={{ padding: '0 1.5rem', marginRight: '0.8%', marginLeft: '1%', width: '18%' }}
                    >
                      Importar
                    </Button>
                  )}
                  {!curso?.cadastroFinalizado && (
                    <Col
                      md={{ span: 3, offset: 0 }}
                      xs={{ span: 6, offset: 3 }}
                      className="d-flex align-items-center"
                    >
                      <Button
                        type="button"
                        style={{ backgroundColor: '#43af56' }}
                        color="#fff"
                        onClick={handleFinalizarCurso}
                      >
                        Concluir o jogo!
                      </Button>
                    </Col>
                  )}
                </>
                )}
                {courseId && curso?.cadastroFinalizado && (
                  <Col
                    md={{ span: 3, offset: 0 }}
                    xs={{ span: 6, offset: 3 }}
                    className="d-flex align-items-center"
                  >
                    {!curso?.dataLancamento && (
                      <Button
                        type="button"
                        style={{ backgroundColor: '#43af56' }}
                        color="#fff"
                        onClick={() => setIsOpenModalFormAgendamento(true)}
                      >
                        Programar lançamento
                      </Button>
                    )}
                    {curso?.dataLancamento != null && (
                      <Button
                        style={{ backgroundColor: '#e22c2c' }}
                        type="button"
                        color="#fff"
                        onClick={handleDisableScheduler}
                      >
                        Desativar Conteúdo
                      </Button>
                    )}
                  </Col>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Header>

      <Row>
        <Col md={4}>
          <BoxItemGame
            header="Curso"
            icon={SiCircle}
            items={curso ? mapCursoItens([curso]) : []}
            onShowFormEdit={() => setShowModalCurso(true)}
            selectedId={curso?.id}
            onDeleteItem={handleDeleteCurso}
            onShowFormModal={() => {
              setShowModalCurso(true);
            }}
            superiorSelecionado={!courseId}
            disabledButtonAdd={!!courseId}
            displayTitle={handleDisplayTitle}
          />
        </Col>
        <Col md={4}>
          <BoxItemGame
            header="Fases"
            messageEmpty="Clique em + para criar os módulos/fases/etapas do seu curso"
            icon={IoScanCircleOutline}
            items={fases}
            onShowFormEdit={() => setShowModalFormFase(true)}
            superiorSelecionado={!!curso?.id}
            selectedId={faseSelecionada?.id}
            onSelecetItem={handleSelectItemFase}
            onDuplicate={handleDuplicarFase}
            onDragEnd={handleOnDragEndFase}
            onDeleteItem={handleDeleteFase}
            onShowFormModal={() => {
              setFaseSelecionada(undefined);
              setShowModalFormFase(true);
              setTarefas([]);
            }}
            displayTitle={handleDisplayTitle}
          />
        </Col>

        <Col md={4}>
          <BoxItemGame
            header="Tarefas"
            messageEmpty="Clique em + para criar as tarefas e quizes do seu curso"
            icon={BsCircle}
            superiorSelecionado={!!faseSelecionada}
            onShowFormEdit={() => setShowModalFormTarefa(true)}
            items={tarefas}
            selectedId={tarefaSelecionada?.id}
            onSelecetItem={handleSelectItemTarefa}
            onDuplicate={handleDuplicarTarefa}
            onDeleteItem={handleDeleteTarefa}
            onDragEnd={handleOnDragEndTarefa}
            onShowFormModal={() => {
              setTarefaSelecionada(undefined);
              setShowModalFormTarefa(true);
            }}
            displayTitle={handleDisplayTitle}
          />
        </Col>
      </Row>
    </>
  );
};
