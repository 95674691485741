import styled from 'styled-components';

export const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 3px;
  right: 3px;
  background: gray;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 12px;
`;

export const StyledImage = styled.img`
  max-width: 200px;
  margin-bottom: 10px;
`;
