import { ImTree } from 'react-icons/im';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Input } from '../../../../components/Input';
import { ModalComponent } from '../../../../components/Modal';
import { Button } from '../../../../components/Button';
import theme from '../../../../styles/theme';
import { IBusinessUnit } from '../../../../interfaces/IBusinessUnit';
import api from '../../../../services/api';
import { UFS_SELECT_OPTIONS } from '../../../../utils/shared-functions';
import { Container } from '../../../Behaviour-Questions/styles';
import { mapAddBusinessUnitErrors } from './ModalFormUnit.helpers';
import ImageInputWithPreview from '../../../../components/Input/ImageInput';

interface ModalFormUnitProps {
  onCloseModal: () => void;
  onSetBusinessUnit: (businessUnit: IBusinessUnit, update?: boolean) => void;
  isOpen: boolean;
  businessUnit: IBusinessUnit | undefined;
  unidadeEmpresaPaiId?: number;
}

export const ModalFormUnit = ({
  onCloseModal, isOpen, businessUnit, onSetBusinessUnit, unidadeEmpresaPaiId,
}: ModalFormUnitProps) => {
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>();
  const [preview, setPreview] = useState<string | null>();
  const [uf, setUf] = useState<any>();

  const initialValue: IBusinessUnit = {
    id: 0,
    nome: '',
    uf: '',
    matrix: false,
    metaNps: 1,
    endereco: '',
    cep: '',
    urlGoogleBusiness: undefined,
  };

  useEffect(() => {
    if (businessUnit) {
      setPreview(businessUnit?.logoUnidadeEmpresa);
      setSelectedImage(businessUnit?.logoUnidadeEmpresa);
      setUf({
        value: businessUnit.uf,
        label: businessUnit.uf,
      });
    } else {
      setPreview(undefined);
      setSelectedImage(undefined);
    }
  }, [businessUnit]);

  // Convert the selected image to Base64
  const convertToBase64 = (file?: File): Promise<string> => new Promise((resolve, reject) => {
    if (!file) return;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

  const handleSubmit = useCallback(async (values: IBusinessUnit) => {
    setLoading(true);
    try {
      // Prepare data for submission
      const formattedData = {
        ...values,
        unidadeEmpresaPaiId,
        logoUnidadeEmpresa: selectedImage, // Attach the Base64 string
      };

      // Send the data to the API
      let response;
      if (businessUnit) {
        response = await api.put(`/unidade-empresa/${businessUnit.id}`, formattedData);
        onSetBusinessUnit(response.data.data, true);
      } else {
        response = await api.post('/unidade-empresa', formattedData);
        onSetBusinessUnit(response.data.data, false);
      }
    } catch (error: any) {
      if (error.response?.data?.errors) {
        const { errors } = error.response.data;
        errors
          .forEach((errorMessage: string) => toast.error(mapAddBusinessUnitErrors(errorMessage)));
      } else {
        toast.error('Houve um problema ao adicionar essa unidade. Contate nosso suporte.');
      }
      console.error('Houve um erro ao adicionar a foto:', error);
    } finally {
      setLoading(false);
      onCloseModal();
    }
  }, [businessUnit, onCloseModal, onSetBusinessUnit, selectedImage]);

  const schema = yup.object({
    nome: yup.string().required('O nome é obrigatório'),
    metaNps: yup.number().required('O NPS é obrigatório'),
  });

  const SelectUFs = (
    { field, ...props } : any,
  ) => (
    <div style={{ marginTop: '0', paddingTop: '0.2rem' }}>
      <label style={{ marginBottom: '0.5rem' }} className="label">
        UF
        {' '}
      </label>
      <Select
        name={field.name}
        isDisabled={props.disabled}
        options={UFS_SELECT_OPTIONS}
        defaultValue={uf?.value || UFS_SELECT_OPTIONS[0].value}
        value={UFS_SELECT_OPTIONS.find((element) => element.value === field.value || 0)}
        onChange={(option: any) => {
          props.form.setFieldValue('uf', option?.value, false);
          setUf(option?.value);
        }}
      />
      <ErrorMessage name={field.name} component="span" className="error is-invalid red-color" />
    </div>
  );

  const handleUploadImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = await convertToBase64(event.target.files?.[0]);
    if (file) {
      setSelectedImage(file);
      setPreview(file);
    }
  };

  const handleDeleteUploadedImage = () => {
    setPreview(undefined);
    setSelectedImage(undefined);
  };

  return (
    <ModalComponent
      onClose={onCloseModal}
      modalVisible={isOpen}
      title="Incluir Unidade de Empresa"
      icon={ImTree}
      size="lg"
    >
      <h6>Preencha todos os dados solicitados.</h6>
      <Formik
        initialValues={businessUnit || initialValue}
        enableReinitialize
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Input type="text" name="nome" label="Nome" isRequerid />
          <Input type="number" min={1} max={100} name="metaNps" label="Meta NPS" isRequerid />
          <Input type="text" name="endereco" label="Endereço" />
          <Input type="number" name="cep" mask="99999-999" label="CEP" />

          <Container>
            <Field
              name="uf"
              component={SelectUFs}
              isRequerid
            />
          </Container>
          <br />
          <Input type="text" name="urlGoogleBusiness" label="URL Google Meu Negócio" />
          <br />
          <ImageInputWithPreview
            preview={preview}
            handleDeleteUploadedImage={handleDeleteUploadedImage}
            handleUploadImage={handleUploadImage}
          />

          <Row className="mt-4">
            <Col md={{ span: 4, offset: 4 }}>
              <Button
                background={theme.colors.main}
                color="#fff"
                className="text-uppercase"
                loading={loading}
                type="submit"
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </ModalComponent>
  );
};
